<template lang="pug">  
  .kr-brand-icon(:class="dynamicClasses", v-html="methodIconContent")
</template>

<script>
import { mapGetters } from 'vuex'
import { loadAssets } from '@/common/loader/assets'
import { resolveBrand } from '@/common/util/brand.js'

export default {
  name: 'BrandIcon',
  props: {
    method: {
      type: String,
      default: 'DEFAULT'
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icon: null,
      isCards: false,
      assetsLoading: true
    }
  },
  computed: {
    ...mapGetters(['getCustomPaymentMethodIcon']),
    dynamicClasses() {
      return {
        [`kr-${this.method.toLowerCase()}`]: true,
        'kr-cards': this.isCards,
        'kr-loading-assets': this.assetsLoading
      }
    },
    methodIconContent() {
      const placeholder = '<span class="kr-placeholder"></span>'
      return (
        this.getCustomPaymentMethodIcon(this.method) || this.icon || placeholder
      )
    }
  },
  watch: {
    async method() {
      this.assetsLoading = true
      await this.loadIcon()
    },
    compact: 'loadIcon'
  },
  async created() {
    await this.loadIcon()
  },
  methods: {
    async loadIcon() {
      const { regular, paymentMethods } = await loadAssets()

      // Get the appropriate brand if its a card brand (resolve coBrands)
      const resolvedMethod = resolveBrand(this.method, false)

      this.isCards = !!regular.branded[resolvedMethod]
      let methodIcon = paymentMethods.regular[resolvedMethod]
      // Grid compact mode specific icons
      const compactMethodIcon = paymentMethods.compact[resolvedMethod]
      methodIcon = (this.compact ? compactMethodIcon : methodIcon) || methodIcon
      this.icon = regular.branded[resolvedMethod] || methodIcon

      this.assetsLoading = false
    }
  }
}
</script>
