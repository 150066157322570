import { mapState, mapGetters } from 'vuex'

export default {
  inject: {
    context: {
      default: null
    }
  },
  computed: {
    ...mapGetters(['hasSmartButton', 'isSmartFormOpen']),
    ...mapState({
      errorExtraContent: state => state.form.error.extra.content,
      error: state => state.error,
      warning: state => state.warning
    }),
    dynamicClasses() {
      return {
        'kr-form-error-visible': this.showFormError,
        'kr-form-error--warning': this.hasWarning && !this.hasError
      }
    },
    extraClasses() {
      return {}
    },
    /**
     * Has data to display
     *
     * @returns {boolean}
     */
    hasData() {
      return (this.hasError || this.hasWarning) && this.matchFormId
    },
    /**
     * Whether there is an error or not.
     *
     * @returns {boolean}
     */
    hasError() {
      return (
        !!this.error.errorCode &&
        (!this.error.metadata || !this.error.metadata.isHiddenOnForm)
      )
    },
    /**
     * Whether there is a warning or not.
     *
     * @returns {boolean}
     */
    hasWarning() {
      return !!this.warning?.errorCode && !!this.warning?.metadata?.showInForm
    },
    /**
     * If a specific formId is defined in current subtree context AND in the
     * error, return true if they match else false.
     *
     * Return true in any other case.
     *
     * @returns {boolean}
     */
    matchFormId() {
      const formId = this?.context?.formId ?? null
      if (formId) {
        if (this.error.formId) {
          return formId === this.error.formId
        }
      }
      return true
    },

    /**
     * Return true if error is specific to a payment method for which a
     * SmartButtonError should already handle it in order to avoid duplicate.
     *
     * @returns {boolean}
     * @since KJS-4478
     */
    isSmartButtonError() {
      const paymentMethod = this.error?.paymentMethod
      if (!paymentMethod) return false
      if (paymentMethod === 'CARDS') {
        return this.hasSmartButton(paymentMethod) && !this.isSmartFormOpen
      }
      return this.hasSmartButton(paymentMethod)
    }
  }
}
