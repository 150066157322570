<template lang="pug">
extends ../controls/templates/FormError.pug
</template>

<script>
import MethodsListError from '@/host/components/mixins/MethodsListError'
import { mapGetters } from 'vuex'

export default {
  name: 'SmartFormError',
  mixins: [MethodsListError],
  computed: {
    ...mapGetters([
      'isAnyPopinOpen',
      'isSmartFormPopin',
      'isExtrasFormVisible'
    ]),
    showFormError() {
      return (
        this.hasData &&
        !this.isSmartButtonError &&
        (this.isRegularError || this.hasWarning) &&
        (!this.isAnyModalOpen || this.isSmartFormPopin)
      )
    },
    isAnyModalOpen() {
      return this.isAnyPopinOpen || this.isExtrasFormVisible
    },
    // The error has a payment method attached
    hasPaymentMethod() {
      return !!this.error?.paymentMethod
    }
  }
}
</script>
