import { isString, contains } from 'underscore'
import { logSentryException } from '@/common/util/sentry'
import {
  isFullWhitelisted,
  isCodeWhitelisted,
  getMetadata,
  hasTransaction,
  getTransaction,
  format,
  formatMsg,
  logError,
  logWarning,
  shouldLogSentry
} from '@/common/util/error'
import ErrorsConfig from '@/configuration/sources/ErrorsConfiguration.yml'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

export const getErrorState = () => {
  return {
    error: {
      errorCode: null,
      orderCycle: null,
      cardOrderCycle: null,
      errorMessage: null,
      detailedErrorCode: null,
      detailedErrorMessage: null,
      paymentMethod: null,
      children: [],
      formId: null,
      metadata: null //console, prefix, isHiddenOnForm, stacktrace...
    },
    transactions: [],
    warning: {
      errorCode: null,
      errorMessage: null,
      metadata: {
        showInForm: false
      }
    }
  }
}

export const errorActions = {
  registerTransaction({ commit, state }, transaction) {
    const transactions = state.transactions
    if (!~transactions.indexOf(transaction)) {
      transactions.push(transaction)
      commit('UPDATE', { transactions })
    }
  },
  error({ commit, dispatch, state, getters }, error = {}) {
    // Reset error
    commit('UPDATE', { error: getErrorState().error })

    // In case is a JS error - wrap it
    if (error instanceof Error)
      error = {
        metadata: {
          stacktrace: error.toString(),
          stack: error.stack
        }
      }
    // Accept error code as string
    if (isString(error)) error = { errorCode: error }
    // Only metadata (server error), extract the data from there
    if (!('errorCode' in error) && error.metadata) error = getMetadata(error)
    // By default, use 'Technical error' error
    if (!('errorCode' in error)) error.errorCode = 'CLIENT_999'

    if (error.errorCode === 'CLIENT_312') {
      // Special case for merchant defined errors
      error.formId = state.forms[state.activeForm]
      error.type = 'error'
      commit('UPDATE', { error })
      logSentryException(error, '')
      return
    }

    // Production fallback (for non whitelisted errors)
    if (!state.testKeys && !isFullWhitelisted(error.errorCode)) {
      // Use only ACQ_001 message
      if (isCodeWhitelisted(error.errorCode)) error._errorKey = 'ACQ_001'
      // Use ACQ_001 code and message
      else error.errorCode = 'ACQ_001'
    }

    // Not a client side error or aborted redirection
    const { errorCode } = error
    const isAbortedError = errorGetters.isAbortedError({ error: { errorCode } })
    const isTechnicalError = errorCode === 'CLIENT_999'
    const { isCardMethodActive, paymentMethod } = getters
    if (!errorCode.includes('CLIENT_') || isAbortedError || isTechnicalError) {
      // transaction register
      if (hasTransaction(error))
        dispatch('registerTransaction', getTransaction(error))
      // Server Date
      if (error.serverDate) {
        dispatch('update', { serverDateTime: error.serverDate })
        delete error.serverDate
      }
      // Close redirection - except for smartform redirection
      if (isCardMethodActive || !paymentMethod) {
        dispatch('finishRedirection')
      }
      // Clean CVV
      dispatch('fieldClear')
    }

    // Path
    const path = error.path || ''

    // format
    error = format({ state, getters }, error)

    // If errorCode was not found in translation, try again once
    // translation are loaded in order to update the errorMessage
    if (error.errorMessage === error.errorCode) {
      getters.onErrorTranslationLoaded(() => {
        error = format({ state, getters }, error)
        logError(error)
        commit('UPDATE', { error })
      })
    } else {
      logError(error)
    }

    // We only report to sentry Server Side errors
    if (shouldLogSentry(error)) logSentryException(error, path)

    commit('UPDATE', { error })

    dispatch('paymentEnd')
    if (!state.redirectDebug && !hasTransaction(error)) dispatch('closeWindow')
  },
  warning({ commit, getters, state }, warn = {}) {
    // Accept error code as string
    if (isString(warn)) warn = { errorCode: warn }

    // Message - check extras
    const { errorCode } = warn
    warn.errorMessage = formatMsg({ getters }, errorCode, errorCode, warn)

    // If translations have not loaded yet
    if (warn.errorMessage === warn.errorCode) {
      getters.onErrorTranslationLoaded(() => {
        const msg = formatMsg({ getters }, errorCode, errorCode, warn)
        if (warn.errorMessage && ~warn.errorMessage.indexOf('%translation%')) {
          warn.errorMessage = warn.errorMessage.replace('%translation%', msg)
        } else {
          warn.errorMessage = msg
        }

        logWarning(warn, commit)
      })
    } else {
      logWarning(warn, commit)
    }
  },
  updateWarningMessage({ commit, getters, state }) {
    const warn = state.warning
    if (!warn.errorCode) return
    const { errorCode } = warn
    warn.errorMessage = formatMsg({ getters }, errorCode, errorCode, {
      errorCode
    })
    commit('UPDATE', { warning: warn })
  },
  cleanError({ commit }) {
    commit('UPDATE', {
      error: getErrorState().error,
      warning: getErrorState().warning
    })
  }
}
export const errorMutations = {}
export const errorGetters = {
  hasError: ({ error }) => !!error.errorCode,
  isAbortedError: ({ error }) =>
    [
      'CLIENT_101',
      'CLIENT_106',
      ...Object.values(paymentMethodsConf.customAbortedErrorCode || {})
    ].includes(error.errorCode),
  errorDisableToolbar: ({ error }) =>
    contains(ErrorsConfig.disableToolbar, error.errorCode),
  shouldShowAlert: ({ error, testKeys }, { errorDisableToolbar }) =>
    testKeys &&
    !!error.errorCode &&
    (errorDisableToolbar || contains(ErrorsConfig.showAlert, error.errorCode)),
  abortedErrorCode: ({ smartForm: { activeMethod }, amount }) => {
    if (!amount) return 'CLIENT_106'
    return (
      paymentMethodsConf.customAbortedErrorCode[activeMethod] ?? 'CLIENT_101'
    )
  }
}
