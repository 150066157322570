export default class ScriptLoader {
  scripts = []

  constructor(locator) {
    this.$locator = locator
  }

  loadScriptPromise(src, className, allowDuplicate) {
    return new Promise(resolve => {
      const foundScript = this._getScript(src)
      if (!allowDuplicate && foundScript) {
        if (foundScript.isLoaded) resolve()
        else foundScript.onLoadCallbacks.push(resolve)
      }

      this.loadScript(src, className, allowDuplicate, { onLoad: resolve })
    })
  }

  loadScript(
    src,
    className = null,
    allowDuplicate = false,
    options = { onload: undefined }
  ) {
    const foundScript = this._getScript(src)
    if (!allowDuplicate && foundScript) {
      return
    }

    const scriptState = {
      src,
      onLoadCallbacks: [],
      isLoaded: false
    }

    if (options.onLoad) scriptState.onLoadCallbacks.push(options.onLoad)
    this.scripts.push(scriptState)

    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('class', `${className}-script`)
    script.setAttribute('src', src)
    script.addEventListener('load', this.onLoad(src))
    document.querySelector('body').appendChild(script)
  }

  onLoad(src) {
    const foundScript = this._getScript(src)
    return () => {
      foundScript.isLoaded = true
      foundScript.onLoadCallbacks.forEach(cb => cb())
    }
  }

  _getScript(src) {
    return this.scripts.find(script => script.src === src)
  }
}
