import BaseDetector from '@/host/service/BaseDetector'
import { fieldNameAsSelector } from '@/common/util/fieldName'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'
import { FormTemplate } from '@/common/model'

const getElement =
  selector =>
  ($el = document, onlyTaggedElements = false) => {
    if (!$el) return null

    let sel = selector
    // format selector to add [kr-element]
    if (onlyTaggedElements) sel = formatTaggedSelector(selector)

    return $el.querySelector(sel)
  }

const getElements =
  selector =>
  ($el = document, onlyTaggedElements = false) => {
    if (!$el) return null

    let sel = selector
    // format selector to add [kr-element]
    if (onlyTaggedElements) sel = formatTaggedSelector(selector)
    return Array.from(($el ? $el : document).querySelectorAll(sel))
  }

function formatTaggedSelector(selector) {
  return selector
    .split(', ')
    .map(sel => {
      return `${sel}[kr-element]`
    })
    .join(', ')
}

export const getScriptElement = () =>
  Array.from(document.querySelectorAll('script[kr-public-key]')).find(
    $script => !!BaseDetector.read($script, 'V4.0')
  ) || null

export const getFormElement = getElement('.kr-embedded')
export const getSmartFormElement = getElement('.kr-smart-form')
export const getAnyFormElement = getElement('.kr-smart-form, .kr-embedded')
export const getSmartButtonElements = getElements('.kr-smart-button')
export const getSmartButtonWrapperElements = getElements(
  '.kr-smart-button-wrapper'
)
export const getStandalonePaymentButton = getElement(
  '.kr-standalone-payment-button'
)
export const getSlaves = () =>
  document.querySelectorAll('iframe[name^="krfield-"]')

export const hasSmartElements = ($el = document, onlyTaggedElements = false) =>
  !!(
    getSmartFormElement($el, onlyTaggedElements) ||
    getSmartButtonElements($el, onlyTaggedElements).length ||
    getSmartButtonWrapperElements($el, onlyTaggedElements).length
  )
export const hasStandalonePaymentButton = (
  $el = document,
  onlyTaggedElements
) => !!getStandalonePaymentButton($el, onlyTaggedElements)

export const getFieldElement = (fieldName, onlyTaggedElements = false) => {
  const selector = onlyTaggedElements
    ? `.kr-embedded[kr-element] ${fieldNameAsSelector(fieldName)}`
    : `.kr-embedded ${fieldNameAsSelector(fieldName)}`

  return document.querySelector(selector)
}

/**
 * Fills the form with the missing required fields and controls
 */
export const fillRequiredElements = (formEl, withControls = true) => {
  const form = new FormTemplate(formEl)
  form.read()
  form.setMerchantElements()
  form.fill(withControls)
}

/**
 * Checks if the form is already rendered by searching for the elements
 * generated during the render
 */
export const isFormRendered = (onlyTaggedElements = false) => {
  for (const field of fieldTypes.iframe) {
    const $field = getFieldElement(field, onlyTaggedElements)
    if (!$field) continue
    const hasChildren = !!$field.childNodes.length
    const hasIcon = $field.querySelector('.kr-icon-wrapper-root')
    const hasFieldWrap = $field.querySelector('.kr-iframe-wrapper')
    const hasIframe = $field.querySelector(`iframe[name="krfield-${field}"]`)
    // If any iframe field has the generated content means it is already rendered
    if (hasChildren && hasIcon && hasFieldWrap && hasIframe) return true
  }
}

export const isElementVisible = $el => {
  if (!$el) return false
  const styles = getComputedStyle($el)
  const { top, bottom, left, right } = $el.getBoundingClientRect()
  const isInViewport =
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  return (
    styles.visibility !== 'hidden' &&
    styles.display !== 'none' &&
    $el.getClientRects().length &&
    isInViewport
  )
}

export const findSelectorElement = selector => {
  // Try with id
  const $element = document.getElementById(selector.replace('#', ''))
  if ($element) return $element

  try {
    // If selector was explicitly provided as an ID but was not found, we should
    // return an error immediately
    if (/^#/.test(selector)) return document.querySelector(selector)
    // Try with class
    const isClass = /^\..+$/.test(selector)
    const prefix = isClass ? '' : '.'
    const $elementByClass = document.querySelector(prefix + selector)
    if ($elementByClass) return $elementByClass
  } catch (e) {
    return null
  }

  return null
}

export const areElementsOverlapped = ($el1, $el2) => {
  const rect1 = $el1.getBoundingClientRect()
  const rect2 = $el2.getBoundingClientRect()
  if (!$el1.getClientRects().length || !$el2.getClientRects().length)
    return false
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  )
}

export const innerText = $el => {
  return 'innerText' in $el ? $el.innerText : $el.textContent
}

export const getDomFields = () => {
  const fields = []
  for (const field of fieldTypes.dom) {
    const $field = document.querySelector(
      `${fieldNameAsSelector(field)}:not([kr-resource])`
    )
    // The field selector is present but already rendered
    if (!!$field) fields.push(field)
  }

  return fields
}

export const getElementFont = el => {
  const styles = window.getComputedStyle(el)
  return styles.font
}

/**
 * @param {DOMElement} el
 * @param {string[]} properties
 * @returns {Object}
 */
export const getElementStyles = (el, properties) => {
  const res = {}
  const styles = window.getComputedStyle(el)
  for (const name in properties) {
    res[name] = styles[name]
  }
  return res
}

/**
 * @param {string} text
 * @param {Object} properties
 * @returns {number}
 */
export const measureTextWidth = (text, properties) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  for (const name in properties) {
    context[name] = properties[name]
  }
  return Math.ceil(context.measureText(text).width)
}

export function removeNodes(selector, $parentEl = document) {
  $parentEl.querySelectorAll(selector).forEach(el => el.remove())
}

export function emptyNodes(selector, $parentEl = document) {
  $parentEl.querySelectorAll(selector).forEach(el => (el.textContent = ''))
}

export function castToHTMLElements($elements) {
  if (Array.isArray($elements))
    return $elements.map($el => {
      return typeof $el === 'string' ? document.querySelector($el) : $el
    })

  return typeof $elements === 'string'
    ? [document.querySelector($elements)]
    : [$elements]
}

function getYcenter(wrapper, child) {
  return wrapper.clientHeight / 2 - child.clientHeight / 2
}

function getXcenter(wrapper, child) {
  return wrapper.clientWidth / 2 - child.clientWidth / 2
}

export function translateVerticalAlign(wrapper, child) {
  return `translate(0px, ${getYcenter(wrapper, child)}px)`
}

export function translateToParentEnd(wrapper, child) {
  const centeredYpos = getYcenter(wrapper, child)
  const xPos = wrapper.clientWidth - child.clientWidth

  return `translate(${xPos}px, ${centeredYpos}px)`
}
